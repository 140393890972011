import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_ADMIN } from 'src/routes/paths';
import permissionKeys from 'src/routes/permissionKeys';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  alumniStory: <MIcon src={path('ic_student')} />,
  walkInEnquiry: <MIcon src={path('ic_chat')} />,
  videoLearning: <MIcon src={path('ic_video')} />,
  livestream: <MIcon src={path('ic_livestream')} />,
  program: <MIcon src={path('ic_program')} />,
  accounting: <MIcon src={path('ic_accounting')} />,
  finance: <MIcon src={path('ic_finance')} />,
  promotion: <MIcon src={path('ic_promotion')} />,
  signature: <MIcon src={path('ic_signature')} />,
  master: <MIcon src={path('ic_master')} />,
  transaction: <MIcon src={path('ic_cart')} />,
  report: <MIcon src={path('ic_report')} />,
  user: <MIcon src={path('ic_user')} />,
  student: <MIcon src={path('ic_student')} />,
  scholarship: <MIcon src={path('ic_scholarship')} />,
  paylater: <MIcon src={path('ic_scholarship')} />,
  exam: <MIcon src={path('ic_exam')} />,
  academicSchedule: <MIcon src={path('ic_calendar')} />,
  community: <MIcon src={path('ic_chat')} />,
  youtube: <MIcon src={path('ic_youtube')} />,
  notification: <MIcon src={path('ic_notification')} />,
  alumni: <MIcon src={path('ic_alumni')} />,
  pac: <MIcon src={path('ic_pac')} />,
  company: <MIcon src={path('ic_corporate')} />,
  activity: <MIcon src={path('ic_activity')} />,
  jobPosting: <MIcon src={path('ic_job_posting')} />,
  contact: <MIcon src={path('ic_contact')} />,
  lecture: <MIcon src={path('ic_lecture')} />,
  employees: <MIcon src={path('ic_employees')} />,
  evaluation: <MIcon src={path('ic_evaluation')} />,
  scoring: <MIcon src={path('ic_scoring')} />,
  tribe: <MIcon src={path('ic_tribe')} />,
  credential: <MIcon src={path('ic_credential')} />,
  support: <MIcon src={path('ic_support')} />,
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        key: permissionKeys.general.dashboard,
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_ADMIN.generalSection.dashboard,
      },
      {
        key: permissionKeys.general.alumniStory,
        title: 'Alumni Story',
        icon: ICONS.alumniStory,
        href: PATH_ADMIN.generalSection.alumniStory.list,
      },
      {
        key: permissionKeys.general.walkInEnquiry,
        title: 'Walk In Enquiry',
        icon: ICONS.walkInEnquiry,
        href: PATH_ADMIN.generalSection.walkInEnquiry.root,
      },
      {
        key: permissionKeys.general.onlineEnquiry,
        title: 'Online Enquiry',
        icon: ICONS.walkInEnquiry,
        href: PATH_ADMIN.generalSection.onlineEnquiry.root,
      },
      {
        key: permissionKeys.general.registrationEnquiry,
        title: 'Registration Enquiry',
        icon: ICONS.walkInEnquiry,
        href: PATH_ADMIN.generalSection.registrationEnquiry.root,
      },
      {
        key: permissionKeys.general.corporateEnquiry,
        title: 'Corporate Enquiry',
        icon: ICONS.walkInEnquiry,
        href: PATH_ADMIN.generalSection.corporateEnquiry.root,
      },
    ],
  },
  {
    subheader: 'program',
    items: [
      {
        title: 'program',
        icon: ICONS.program,
        href: PATH_ADMIN.programSection.program.root,
        items: [
          {
            key: permissionKeys.program.root,
            title: 'list',
            href: PATH_ADMIN.programSection.program.list,
          },
          // {
          //   key: 'Attendees',
          //   title: 'attendees',
          //   href: PATH_ADMIN.programSection.program.attendees,
          // },
          {
            key: permissionKeys.program.syllabus,
            title: 'syllabus',
            href: PATH_ADMIN.programSection.program.syllabus,
          },
          // {
          //   key: 'Student Attendance',
          //   title: 'student attendance',
          //   href: PATH_ADMIN.programSection.program.studentAttendance,
          // },
        ],
      },
      {
        title: 'video learning',
        icon: ICONS.videoLearning,
        href: PATH_ADMIN.programSection.videoLearning.root,
        items: [
          {
            key: permissionKeys.videoLearning.root,
            title: 'list',
            href: PATH_ADMIN.programSection.videoLearning.list.root,
          },
          {
            key: permissionKeys.videoLearning.access,
            title: 'access',
            href: PATH_ADMIN.programSection.videoLearning.access,
          },
          {
            key: permissionKeys.videoLearning.practiceSession,
            title: 'Practice Session',
            href: PATH_ADMIN.programSection.videoLearning.practiceSession.root,
          },
          {
            key: permissionKeys.videoLearning.studentQuestion,
            title: 'student question',
            href: PATH_ADMIN.programSection.videoLearning.studentQuestion,
          },
        ],
      },
      {
        title: 'livestream',
        icon: ICONS.livestream,
        href: PATH_ADMIN.programSection.livestream.root,
        items: [
          {
            key: permissionKeys.livestream.root,
            title: 'list',
            href: PATH_ADMIN.programSection.livestream.list,
          },
          {
            key: permissionKeys.livestream.access,
            title: 'access',
            href: PATH_ADMIN.programSection.livestream.access,
          },
          {
            key: permissionKeys.livestream.schedule,
            title: 'schedule',
            href: PATH_ADMIN.programSection.livestream.schedule,
          },
        ],
      },
      {
        title: 'exam',
        icon: ICONS.scoring,
        href: PATH_ADMIN.programSection.exam.root,
        items: [
          {
            key: permissionKeys.exam.root,
            title: 'list',
            href: `${PATH_ADMIN.programSection.exam.list}?page=1`,
          },
          {
            key: permissionKeys.exam.schedulesTranscript,
            title: 'schedules & transcript',
            href: `${PATH_ADMIN.programSection.exam.batchList}`,
          },
        ],
      },
      {
        title: 'grades',
        icon: ICONS.credential,
        href: PATH_ADMIN.programSection.grade.root,
        items: [
          {
            key: permissionKeys.grade.root,
            title: 'list',
            href: PATH_ADMIN.programSection.grade.root,
          },
          {
            key: permissionKeys.grade.batch,
            title: 'batch list',
            href: PATH_ADMIN.programSection.grade.batch.root,
          },
        ],
      },
      {
        title: 'evaluation',
        icon: ICONS.evaluation,
        href: PATH_ADMIN.programSection.evaluation.root,
        items: [
          {
            key: permissionKeys.evaluation.root,
            title: 'batch list',
            href: `${PATH_ADMIN.programSection.evaluation.batch}?page=1`,
          },
        ],
      },
      {
        title: 'digital signature',
        icon: ICONS.signature,
        href: PATH_ADMIN.programSection.digitalSignature.root,
        items: [
          // {
          //   key: 'Digital Signature Registration',
          //   title: 'registration',
          //   href: PATH_ADMIN.programSection.digitalSignature.registration,
          // },
          {
            key: permissionKeys.digitalSignature.root,
            title: 'document',
            href: PATH_ADMIN.programSection.digitalSignature.document.root,
          },
        ],
      },
      {
        key: permissionKeys.scholarship.root,
        title: 'Scholarship',
        icon: ICONS.scholarship,
        href: PATH_ADMIN.programSection.scholarship.root,
      },
      {
        key: permissionKeys.paylater.root,
        title: 'Bayar Setelah Kerja',
        icon: ICONS.scholarship,
        href: PATH_ADMIN.programSection.paylater.root,
      },
      {
        key: permissionKeys.academicSchedule.root,
        title: 'Academic Schedule',
        icon: ICONS.academicSchedule,
        href: PATH_ADMIN.programSection.academicSchedule.root,
      },
      {
        key: permissionKeys.communityPartner.root,
        title: 'Community Partner',
        icon: ICONS.community,
        href: PATH_ADMIN.programSection.communityPartner.root,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      //     {
      //       title: 'master',
      //       icon: ICONS.master,
      //       href: PATH_ADMIN.managementSection.master.root,
      //       items: [
      //         {
      //           key: 'Payment Method',
      //           title: 'payment method',
      //           href: PATH_ADMIN.managementSection.master.paymentMethod,
      //         },
      //         {
      //           key: 'Tujuan Penerimaan',
      //           title: 'tujuan penerimaan',
      //           href: PATH_ADMIN.managementSection.master.tujuanPenerimaan,
      //         },
      //         {
      //           key: 'Payment Method',
      //           title: 'payment method',
      //           href: PATH_ADMIN.managementSection.master.tujuanPengeluaran,
      //         },
      //       ],
      //     },
      //     {
      //       title: 'transaction',
      //       icon: ICONS.transaction,
      //       href: PATH_ADMIN.managementSection.transaction.root,
      //       items: [
      //         {
      //           key: 'Transaction List',
      //           title: 'list',
      //           href: PATH_ADMIN.managementSection.transaction.list,
      //         },
      //       ],
      //     },
      {
        title: 'finance',
        icon: ICONS.finance,
        href: PATH_ADMIN.managementSection.finance.root,
        items: [
          {
            key: permissionKeys.finance.purchaseOrder,
            title: 'purchase order',
            href: PATH_ADMIN.managementSection.finance.purchaseOrder,
          },
          {
            key: permissionKeys.finance.eFaktur,
            title: 'e faktur',
            href: PATH_ADMIN.managementSection.finance.eFaktur,
          },
          {
            key: permissionKeys.finance.transactionInstallment,
            title: 'transaction installment',
            href: PATH_ADMIN.managementSection.finance.transactionInstallment,
          },
          //         {
          //           key: 'EDC Journal',
          //           title: 'EDC journal',
          //           href: PATH_ADMIN.managementSection.finance.edcJournal,
          //         },
        ],
      },
      //     {
      //       title: 'finance',
      //       icon: ICONS.finance,
      //       href: PATH_ADMIN.managementSection.finance.root,
      //       items: [
      //         {
      //           key: 'Purchase Order',
      //           title: 'purchase order',
      //           href: PATH_ADMIN.managementSection.finance.purchaseOrder,
      //         },
      //         {
      //           key: 'E Faktur',
      //           title: 'e faktur',
      //           href: PATH_ADMIN.managementSection.finance.eFaktur,
      //         },
      //         {
      //           key: 'EDC Journal',
      //           title: 'EDC journal',
      //           href: PATH_ADMIN.managementSection.finance.edcJournal,
      //         },
      //       ],
      //     },
      //     {
      //       title: 'accounting',
      //       icon: ICONS.accounting,
      //       href: PATH_ADMIN.managementSection.accounting.root,
      //       items: [
      //         {
      //           key: 'GL Journal',
      //           title: 'GL journal',
      //           href: PATH_ADMIN.managementSection.accounting.glJournal,
      //         },
      //         {
      //           key: 'GL Generator',
      //           title: 'GL generator',
      //           href: PATH_ADMIN.managementSection.accounting.glGenerator,
      //         },
      //         {
      //           key: 'Posting',
      //           title: 'posting',
      //           href: PATH_ADMIN.managementSection.accounting.posting,
      //         },
      //       ],
      //     },
      //     {
      //       title: 'promotion',
      //       icon: ICONS.promotion,
      //       href: PATH_ADMIN.managementSection.promotions.root,
      //       items: [
      //         {
      //           key: 'Voucher Code',
      //           title: 'voucher code',
      //           href: PATH_ADMIN.managementSection.promotions.voucherCode,
      //         },
      //         {
      //           key: 'Referral Code',
      //           title: 'referral code',
      //           href: PATH_ADMIN.managementSection.promotions.referralCode,
      //         },
      //       ],
      //     },
      {
        title: 'promotion',
        icon: ICONS.promotion,
        href: PATH_ADMIN.managementSection.promotions.root,
        items: [
          {
            key: permissionKeys.promotion.voucherCode,
            title: 'voucher code',
            href: PATH_ADMIN.managementSection.promotions.voucherCode,
          },
          {
            key: permissionKeys.promotion.promo,
            title: 'promo',
            href: PATH_ADMIN.managementSection.promotions.promo,
          },
          // {
          //   key: 'Referral Code',
          //   title: 'referral code',
          //   href: PATH_ADMIN.managementSection.promotions.referralCode,
          // },
        ],
      },
    ],
  },
  {
    subheader: 'report',
    items: [
      {
        key: permissionKeys.report.sales,
        title: 'sales report',
        icon: ICONS.report,
        href: PATH_ADMIN.reportSection.salesReport,
      },
      {
        key: permissionKeys.report.programSales,
        title: 'program sales report',
        icon: ICONS.report,
        href: PATH_ADMIN.reportSection.programSalesReport,
      },
      {
        key: permissionKeys.report.transactionInstallment,
        title: 'Installment report',
        icon: ICONS.report,
        href: PATH_ADMIN.reportSection.transactionInstallmentReport,
      },
      {
        key: permissionKeys.report.transactionInstallmentAR,
        title: 'Installment AR report',
        icon: ICONS.report,
        href: PATH_ADMIN.reportSection.transactionInstallmentARReport,
      },
      {
        key: permissionKeys.report.newTransactionInstallment,
        title: 'new installment report',
        icon: ICONS.report,
        href: PATH_ADMIN.reportSection.newTransactionInstallmentReport,
      },
      // {
      //   key: 'Bank Report',
      //   title: 'bank report',
      //   icon: ICONS.report,
      //   href: PATH_ADMIN.reportSection.bankReport,
      // },
      // {
      //   key: 'Enquiry Report',
      //   title: 'enquiry report',
      //   icon: ICONS.report,
      //   href: PATH_ADMIN.reportSection.enquiryReport,
      // },
    ],
  },
  {
    subheader: 'user',
    items: [
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_ADMIN.userSection.user.root,
        items: [
          {
            key: permissionKeys.user.root,
            title: 'list',
            href: PATH_ADMIN.userSection.user.list,
          },
          {
            key: permissionKeys.user.role,
            title: 'role',
            href: PATH_ADMIN.userSection.user.role,
          },
        ],
      },
      {
        title: 'student',
        icon: ICONS.student,
        href: PATH_ADMIN.userSection.student.root,
        items: [
          {
            key: permissionKeys.student.root,
            title: 'student list',
            href: PATH_ADMIN.userSection.student.studentList,
          },
          {
            key: permissionKeys.student.report,
            title: 'student report',
            href: PATH_ADMIN.userSection.student.studentReport,
          },
          {
            key: permissionKeys.student.announcement,
            title: 'student announcement',
            href: PATH_ADMIN.userSection.student.studentAnnouncement,
          },
          // {
          //   key: 'Student Info',
          //   title: 'student info',
          //   href: PATH_ADMIN.userSection.student.studentInfo,
          // },
          {
            key: permissionKeys.student.birthday,
            title: 'student birthday',
            icon: ICONS.report,
            href: PATH_ADMIN.userSection.student.studentBirthday,
          },
          {
            key: permissionKeys.student.support,
            title: 'student support',
            href: PATH_ADMIN.userSection.student.studentSupport,
          },
          {
            key: permissionKeys.student.attendees,
            title: 'attendees',
            href: PATH_ADMIN.userSection.student.attendees,
          },
          {
            key: permissionKeys.student.recommendation,
            title: 'student recommendation',
            href: PATH_ADMIN.userSection.student.studentRecommendation,
          },
        ],
      },
      {
        title: 'lecturer',
        icon: ICONS.lecture,
        href: PATH_ADMIN.userSection.lecturer.root,
        items: [
          {
            key: permissionKeys.lecturer.root,
            title: 'list',
            href: PATH_ADMIN.userSection.lecturer.list,
          },
        ],
      },
      {
        title: 'operational',
        icon: ICONS.employees,
        href: PATH_ADMIN.userSection.branchOperator.root,
        items: [
          {
            key: permissionKeys.operational.root,
            title: 'list',
            href: PATH_ADMIN.userSection.branchOperator.list,
          },
        ],
      },
    ],
  },
  {
    subheader: 'COMMUNITY',
    items: [
      {
        title: 'PAC Member',
        icon: ICONS.pac,
        items: [
          {
            key: permissionKeys.community.pacMember,
            title: 'list',
            href: PATH_ADMIN.communitySection.pacMember.root,
          },
        ],
      },
      {
        title: 'tribe',
        icon: ICONS.tribe,
        items: [
          {
            key: permissionKeys.community.tribe,
            title: 'list',
            href: PATH_ADMIN.communitySection.tribe.root,
          },
        ],
      },
      {
        title: 'activity',
        icon: ICONS.activity,
        items: [
          {
            key: permissionKeys.community.activity,
            title: 'list',
            href: PATH_ADMIN.communitySection.activity.root,
          },
        ],
      },
      {
        title: 'job posting',
        icon: ICONS.jobPosting,
        items: [
          {
            key: permissionKeys.community.jobPosting,
            title: 'list',
            href: PATH_ADMIN.communitySection.jobPosting.root,
          },
        ],
      },
      {
        title: 'alumni',
        icon: ICONS.alumni,
        items: [
          {
            key: permissionKeys.community.alumni,
            title: 'list',
            href: PATH_ADMIN.communitySection.alumni.root,
          },
        ],
      },
      {
        title: 'company',
        icon: ICONS.company,
        items: [
          {
            key: permissionKeys.community.company,
            title: 'list',
            href: PATH_ADMIN.communitySection.company.root,
          },
        ],
      },
      {
        title: 'alumni contact',
        icon: ICONS.contact,
        items: [
          {
            key: permissionKeys.community.alumniContact,
            title: 'list',
            href: PATH_ADMIN.communitySection.alumniContact.list,
          },
          {
            key: permissionKeys.community.alumniContact,
            title: 'enquiry',
            href: PATH_ADMIN.communitySection.alumniContact.enquiry,
          },
        ],
      },
    ],
  },
  {
    subheader: 'e-learning hub',
    items: [
      {
        title: 'Youtube',
        icon: ICONS.youtube,
        href: PATH_ADMIN.eLearningHubSection.youtube.playlists,
        items: [
          {
            key: permissionKeys.elearningHub.youtubePlaylists,
            title: 'playlists',
            href: `${PATH_ADMIN.eLearningHubSection.youtube.playlists}?page=1`,
          },
          {
            key: permissionKeys.elearningHub.youtubeVideos,
            title: 'videos',
            href: `${PATH_ADMIN.eLearningHubSection.youtube.videos}?page=1`,
          },
        ],
      },
    ],
  },

  {
    subheader: 'support',
    items: [
      {
        key: permissionKeys.support.root,
        title: 'Support Ticket',
        icon: ICONS.support,
        href: PATH_ADMIN.SupportSection.supportTicket.list,
        items: [
          {
            key: permissionKeys.support.root,
            title: 'list',
            href: PATH_ADMIN.SupportSection.supportTicket.list,
          },
        ],
      },
    ],
  },
  {
    subheader: 'notification',
    items: [
      {
        key: permissionKeys.notification.root,
        title: 'Notification',
        icon: ICONS.notification,
        href: PATH_ADMIN.notificationSection.root,
      },
    ],
  },
];

const getNavItem = (items = [], availableMenu = []) => {
  const result = [];

  items.forEach((config) => {
    if (config.items && Array.isArray(config.items)) {
      const navItems = getNavItem(config.items, availableMenu);

      if (navItems && Array.isArray(navItems) && navItems.length > 0) {
        result.push({ ...config, items: navItems });
      }
    }

    if (
      (config.key && availableMenu.includes(config.key)) ||
      config.key === 'Dashboard'
    ) {
      result.push(config);
    }
  });

  return result;
};

export const getConfig = (availableMenu = [], showAll = false) => {
  let config = [];

  if (showAll) {
    config = navConfig;
  } else {
    navConfig.forEach(({ items, ...rest }) => {
      const navItems = getNavItem(items, availableMenu);

      if (navItems && navItems.length > 0) {
        config.push({ ...rest, items: navItems });
      }
    });
  }

  return config;
};

export default navConfig;
